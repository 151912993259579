import initialState from './initialState'

const SHOW_WIDGET = 'iam/features/search-widget/SHOW_WIDGET'
const HIDE_WIDGET = 'iam/features/search-widget/HIDE_WIDGET'
const TOGGLE_WIDGET = 'iam/features/search-widget/TOGGLE_WIDGET'

const FETCH_MATERIALS = 'md/features/search-widget/FETCH_MATERIALS'
const FETCH_MATERIALS_SUCCESS = 'md/features/search-widget/FETCH_MATERIALS_SUCCESS'
const FETCH_MATERIALS_FAILED = 'md/features/search-widget/FETCH_MATERIALS_FAILED'

const FETCH_COLORS = 'md/features/search-widget/FETCH_COLORS'
const FETCH_COLORS_SUCCESS = 'md/features/search-widget/FETCH_COLORS_SUCCESS'
const FETCH_COLORS_FAILED = 'md/features/search-widget/FETCH_COLORS_FAILED'

export const showWidget = () => {
  return {
    type: SHOW_WIDGET
  }
}

export const hideWidget = () => {
  return {
    type: HIDE_WIDGET
  }
}

export const toggleWidget = () => {
  return {
    type: TOGGLE_WIDGET
  }
}

export const fetchMaterials = () => {
  return {
    types: [FETCH_MATERIALS, FETCH_MATERIALS_SUCCESS, FETCH_MATERIALS_FAILED],
    callApi: {
      endpoint: 'api/materials/',
      method: 'GET'
    }
  }
}

export const fetchColors = () => {
  return {
    types: [FETCH_COLORS, FETCH_COLORS_SUCCESS, FETCH_COLORS_FAILED],
    callApi: {
      endpoint: 'api/colors/',
      method: 'GET'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_WIDGET:
      return {
        ...state,
        showWidget: true
      }
    case HIDE_WIDGET:
      return {
        ...state,
        showWidget: false
      }
    case TOGGLE_WIDGET:
      return {
        ...state,
        showWidget: !state.showWidget
      }
    case FETCH_MATERIALS:
      return {
        ...state,
        materials: {
          loading: true,
          items: null
        }
      }
    case FETCH_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: {
          items: action.data,
          loading: false
        }
      }
    case FETCH_MATERIALS_FAILED:
      return {
        ...state,
        materials: {
          loading: false
        }
      }
    case FETCH_COLORS:
      return {
        ...state,
        colors: {
          loading: true,
          items: null
        }
      }
    case FETCH_COLORS_SUCCESS:
      return {
        ...state,
        colors: {
          items: action.data,
          loading: false
        }
      }
    case FETCH_COLORS_FAILED:
      return {
        ...state,
        colors: {
          loading: false,
          items: null
        }
      }
    default:
      return state
  }
}