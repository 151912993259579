<raw>
  <span></span>
  <script>
    import './style.scss'
    this.root.innerHTML = opts.content
    this.setContent = () => {
      this.root.innerHTML = opts.content
    }

    this.on('update', this.setContent)
    this.on('mount', this.setContent)
  </script>
</raw>