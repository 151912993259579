import {
  combineReducers
} from 'redux'
import groups from 'tags/features/products/categories/duck'
import articles from 'tags/features/products/shop-feature/duck'

const reducer = combineReducers({
  groups,
  articles
})

export default reducer