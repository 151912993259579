<shop-feature>
  <div class="row">
    <div class="sideNavigation col-xs-12 col-sm-3 col-md-2">
      <categories-navigation />
    </div>
    <div class="articles col-xs-12 col-sm-9 col-md-10">
      <category-items />
    </div>
  </div>
  <script>
    import {
      fetchArticles,
      fetchHighlightedArticles
    } from './duck'
    import {
      urlName
    } from 'lib/utils'
    import './style.scss'
    import './category-items'
    import 'tags/features/products/categories-navigation'
    this.urlName = urlName

    const mapStateToOpts = state => {
      return {
        orderBy: state.products.articles.orderBy,
        search: state.products.articles.search,
        productGroupId: state.products.articles.productGroupId,
        highlights: state.products.articles.highlights
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.updateCategory = () => {
      if (this.opts.shopname === 'search' || this.opts.search) return

      let optsProductGroupId = null

      if (this.opts.shopname !== 'highlighted')
        optsProductGroupId = this.opts.groupid ? Number(this.opts.groupid) : null

      if ((this.opts.productGroupId !== optsProductGroupId) || (!optsProductGroupId && !this.opts.highlights)) {
        if (optsProductGroupId) {
          this.opts.dispatch(fetchArticles(optsProductGroupId, this.opts.orderBy))
        } else {
          this.opts.dispatch(fetchHighlightedArticles())
        }
      }
    }

    this.on('update', () => {
      this.updateCategory()
    })

    this.on('mount', () => {
      this.updateCategory()
    })
  </script>
</shop-feature>