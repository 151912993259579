<default-textarea>
  <div>
    <label for="{name}" if="{label}">{label} <span if={required} class="required">*</span></label>
    <textarea name="{name}" class="{error: error.length}" ref="{ref}" placeholder="{placeholder}"
      disabled="{disabled}" oninput="{onInput}" onblur="{onBlur}">{value}</textarea>
    <div if="{error.length}" class="errormsg">{error}</div>
  </div>
  <script>
    import './style.scss'
    this.label = opts.label || ''
    this.name = opts.name || ''
    this.placeholder = opts.placeholder || ''
    this.ref = opts.ref || ''
    this.value = opts.riotValue || ''
    this.error = opts.error || ''
    this.disabled = opts.disabled || false
    this.required = opts.required || false

    this.onInput = function (e) {
      const {
        value
      } = e.target
      this.value = value
    }

    this.onBlur = function (e) {
      if (opts.onblur) {
        opts.onblur(e)
      }
    }

    this.on('update', () => {
      this.error = opts.error || ''
      this.label = opts.label || ''
      this.placeholder = opts.placeholder || ''
      this.disabled = opts.disabled || false
      this.value = opts.riotValue || ''
    })
  </script>
</default-textarea>