<article-feature>
  <div class="row">
    <div class="row col-sm-10 articleWrapper">
      <loading-spinner if={opts.loading} />
      <div class="row miniNavigation col-md-12">
        <mini-navigation if={opts.article} articletext={opts.article && opts.article.ArticleTextShort} productgroupid={opts.article
          && opts.article.ProductGroupId} />
      </div>
      <div class="animated-fast fadeIn addedToCart" if={opts.addedToCart && opts.article}>
        "{opts.article.ArticleText}" har lagts i din varukorg
        <a class="defaultButton" href="#cart">Till varukorgen</a>
      </div>
      <div if={opts.article} class="articleInfo row col-xs-12">
        <div class="articleImage col-xs-12 col-sm-5">
          <div class="imageWrapper">
            <single-slider if={opts.article && opts.article.ImageId} images={opts.article && opts.article.ImageIds}
              defaultimage={opts.article && opts.article.ImageId} endpoint={endpoint} alt={opts.article &&
              opts.article.ArticleText}
              onimageclicked={showImage} />
            <img if={opts.article && !opts.article.ImageId} src={noimg} alt={opts.article && opts.article.ArticleText}>
          </div>
        </div>
        <div if={opts.article} class="infoContainer col-xs-12 col-sm-7">
          <span class="header">{opts.article.ArticleTextShort}</span>
          <div>ArtikelNr: {opts.article.ArticleNumber}</div>
          <div class="info">
            <span>{opts.article.Description}</span>
            <span if={opts.article}>({opts.article.QuantityPerPackage} st/fp)</span>
          </div>
          <div class="priceInfo">
            <span class="price">Pris: {opts.article.Price.format()}Kr</span>
            <span class="delivery" if={opts.article.EstimatedDeliveryDate}>På väg in ({getDate()})</span>
            <span class="delivery" if={!opts.article.EstimatedDeliveryDate && opts.article.QuantityAvailable}>Finns i
              lager</span>
            <span class="delivery" if={opts.article.QuantityAvailable <=0}>Slutsåld</span>
          </div>
          <div class="amountSelect">
            <label>Antal</label>
            <select ref="quantity" name="quantity" onchange={quantityChanged} value={selectedValue}>
              <option value="" disabled selected>Välj ett alternativ...</option>
              <option each={quantity in addQuantities} value={quantity}>{quantity}</option>
            </select>
          </div>
          <div if={opts.article} class="addToCart">
            <div class="price">
              <span if={selectedValue}>
                Pris:
                <span>{(selectedValue * opts.article.Price).format(2)}kr</span>
              </span>
            </div>
            <button class="defaultButton" onclick={addToCart}>
              <raw content={cart} /> Lägg i varukorg
            </button>
          </div>
          <div class="categoryInfo">
            <span if={opts.article && categoryItem}>
              Kategori:
              <span class="categoryName" onclick={toCategory}>{categoryItem.Name}</span>
            </span>
          </div>
        </div>
      </div>
      <div if={opts.article} class="additionalInfo col-xs-12">
        <div class="row">
          <div class="tab col-xs-6 col-md-2">Mer information</div>
          <div class="tabWhitespace col-xs-6 col-md-10"></div>
        </div>
        <div class="">
          <div class="additionalHeader">
            <h2>Mer information</h2>
          </div>
          <div class="additionalAmount">
            <span class="amount">Antal</span>
            <span class="amountSpans">
              {addQuantities.join(', ')}
            </span>
          </div>
        </div>
      </div>
      <related-articles articleid={articleId} />
    </div>
    <div class="categoriesNavigation col-xs-12 col-sm-2">
      <categories-navigation />
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import 'tags/features/products/categories-navigation'
    import 'tags/features/mini-navigation'
    import 'tags/features/article/related-articles'
    import 'tags/common/single-slider'
    import cart from 'images/svg/shopping-cartbtn.svg'
    import noimg from 'images/siteimages/noimage.jpg'
    import route from 'riot-route'
    import {
      fetchArticle,
      addingToCart
    } from './duck'
    import {
      addToCart
    } from 'tags/features/shoppingcart/duck'
    import {
      urlName,
      formatDeliverytime,
      getDate
    } from 'lib/utils'
    this.apiUrl = process.env.API_URL
    this.endpoint = 'api/articleimage/'
    this.articleId = opts.articleid || null
    this.categoryItem = null
    this.cart = cart
    this.addQuantities = []
    this.selectedValue = null
    this.noimg = noimg

    const mapStateToOpts = state => {
      return {
        loading: state.article.article.loading,
        article: state.article.article.data,
        productCategory: state.products.groups.items,
        addedToCart: state.article.article.addedToCart,
        addingToCart: state.article.article.addingToCart,
        estimatedDeliveryDate: state.app.settings.EstimatedDeliveryDate
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.formatDeliverytime = () => formatDeliverytime(this.opts.article.EstimatedDeliveryDate, this.opts.estimatedDeliveryDate)
    this.getDate = () => getDate(this.opts.article.EstimatedDeliveryDate)
    this.toCategory = () => {
      if (this.categoryItem)
        route('shop/' + urlName(this.categoryItem.Name) + '/' + this.categoryItem.ProductGroupId)
    }

    this.quantityChanged = e => {
      const {
        value
      } = e.target
      this.selectedValue = Number(value)
    }

    this.getCategory = () => {
      if (this.opts.article && this.opts.productCategory && !this.categoryItem) {
        const categoryItem = this.opts.productCategory.find(category => {
          return category.ProductGroupId === Number(this.opts.article.ProductGroupId)
        })

        if(categoryItem) {
          this.categoryItem = categoryItem
          this.update()
        }
      }
    }

    this.addToCart = () => {
      const articleId = this.opts.article.Id
      const quantity = this.refs.quantity.value

      if (quantity && quantity !== '' && !this.opts.addingToCart) {
        this.opts.dispatch(addingToCart())
        this.opts.dispatch(addToCart(articleId, quantity))
      }
    }

    this.fetchArticle = () => {
      if (this.articleId !== this.opts.articleid) {
        this.articleId = this.opts.articleid
        this.opts.dispatch(fetchArticle(this.articleId))
      }
    }

    this.showImage = (item) => {
      window.open(`${this.apiUrl}${this.endpoint}${item.image}`, '_blank')
    }

    this.on('update', () => {
      this.fetchArticle()

      this.getCategory()

      if (!this.addQuantities.length && this.opts.article) {
        this.addQuantities = []

        for (let i = 1; i <= 4; i++) {
          this.addQuantities.push(this.opts.article.QuantityPerPackage * i)
        }
      }
    })

    this.on('mount', () => {
      this.fetchArticle()
      this.getCategory()
    })
  </script>
</article-feature>