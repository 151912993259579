<aboutus-page>
  <div>
    <img if="{!opts.loading && opts.images}" src="{apiUrl}/api/webimage/{opts.images[0].Id}" alt="">
  </div>
  <h2>Om Oss</h2>
  <div class="containerTextContainer row">
    <div class="textContainer col-xs-12 col-sm-8">
      <span if={opts.webTexts}>
        <raw content={nl2br(opts.webTexts.Aboutus)} />
      </span>
      <span>Välkomna till oss!</span>
      <span>Anna och Mattias</span>
    </div>
    <div class="smallImage col-sm-4 col-xs-12 end-md">
      <img if="{!opts.loading && opts.images}" src="{apiUrl}/api/webimage/{opts.images[1].Id}" alt="">
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import {
      nl2br
    } from 'lib/utils'
    import {
      fetchImages
    } from './duck'
    this.mixin('store')

    this.nl2br = nl2br
    this.apiUrl = process.env.API_URL

    const mapStateToOpts = state => {
      return {
        images: state.aboutus.images,
        loading: state.aboutus.loading,
        webTexts: state.app.texts
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.on('mount', () => {
      this.store.dispatch(fetchImages())
    })
  </script>
</aboutus-page>