<welcome-feature>
  <main-slider />
  <div class="row">
    <div class="col-md-8">
      <h3 class="headerText">Varmt välkommen till I AM INTERIOR</h3>
      <div class="infoContainer row col-md-12">
        <div class="textContainer">
          <div if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.Welcome)} />
          </div>
          <div class="textFounders">
            <span>Anna och Mattias</span>
            <span>Grundare I AM interior</span>
          </div>
        </div>
        <div class="infoTexts col-md-4">
          <h3 class="subHeaderText">Kontakta Oss</h3>
          <span>{opts.settings.Address}</span>
          <span>{opts.settings.ZipCode} {opts.settings.City}</span>
          <span>Telefon: <a href="tel: {opts.settings.Telephone}">{opts.settings.Telephone}</a></span>
          <span>E-mail: <a href="mailto: {opts.settings.Email}">{opts.settings.Email}</a></span>
          <div class="socialMediaImages">
            <a href="https://www.facebook.com/IAMinteriorAB" target="_blank">
              <div>
                <raw content={faceBookMini} />
              </div>
            </a>
            <a href="https://www.instagram.com/iaminterior.se" target="_blank">
              <div>
                <raw content={instagramMini} />
              </div>
            </a>
          </div>
        </div>
        <div class="openingtexts col-xs-12 col-sm-4">
          <h3 class="subHeaderText">Våra Öppettider</h3>
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.Openinghours)} />
          </span>
        </div>
        <div class="faceBook col-xs-12 col-sm-4">
          <div>
            <a href="https://www.facebook.com/IAMinteriorAB" target="_blank">
              <img src="{faceBookBtn}" alt="Facebook">
            </a>
          </div>
        </div>
      </div>
      <div class="productCatalogIframe col-xs-12" if="{productCatalogUrl && productCatalogUrl.length}">
        <!-- <h3 class="subHeaderText">Produktkatalog</h3> -->
        <!-- <a href="{productCatalogDownloadUrl}" target="_blank">Klicka här för att ladda ner katalogen</a>
        <iframe allowfullscreen="true" style="border:none;width:100%;"
          src="{productCatalogUrl}">
        </iframe> -->
      </div>
    </div>
    <div class="instagramImg col-md-4">
      <h3 class="subHeaderText">Vårt Instagramflöde</h3>
      <instagram-feed></instagram-feed>
    </div>   
  </div>
  <script>
    import './style.scss'
    import 'tags/features/instagram-feed'
    import 'tags/features/sliders/main-slider'
    import 'tags/common/raw'
    import {
      nl2br
    } from 'lib/utils'
    import magazine from 'images/siteimages/produktkatalog.jpg'
    import faceBookBtn from 'images/siteimages/facebook-btn.png'
    import faceBookMini from 'images/svg/facebook-square.svg'
    import instagramMini from 'images/svg/instagram-square.svg'
    //this.mixin('store')
    this.magazine = magazine
    this.faceBookBtn = faceBookBtn
    this.faceBookMini = faceBookMini
    this.instagramMini = instagramMini
    this.nl2br = nl2br
    this.productCatalogUrl = ''
    this.productCatalogDownloadUrl = ''

    this.setCatalogURL = () => {
      if (this.opts.loggedIn) {
        this.productCatalogUrl = this.opts.catalogPriceURL;
        this.productCatalogDownloadUrl = process.env.API_URL + 'api/DownloadFile/CatalogPriceDownload';
      } else {
        this.productCatalogUrl = this.opts.catalogURL;
        this.productCatalogDownloadUrl = process.env.API_URL + 'api/DownloadFile/CatalogDownload';
      }
    }

    this.on('update', () => {
      this.setCatalogURL();
    })

    this.on('mount', () => {
      this.setCatalogURL();
      this.update();
    })

    const mapStateToOpts = state => {
      return {
        webTexts: state.app.texts,
        settings: state.app.settings.Company,
        catalogURL: state.app.settings.CatalogURL,
        catalogPriceURL: state.app.settings.CatalogPriceURL,
        loggedIn: state.login.loggedIn
      }
    }

    this.reduxConnect(mapStateToOpts)
  </script>
</welcome-feature>