<add-delivery-form>
  <h2 class="header">Lägg till leveransadress</h2>
  <form onsubmit={addDeliveryInfo}>
    <div>
      <default-input type="text" label="Namn" name="name" ref="name" value={opts.form.name} error={errors.name} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Adress1" name="address1" ref="address1" value={opts.form.address1} error={errors.address1}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Address2" name="address2" ref="address2" value={opts.form.address2} error={errors.address2}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Postnummer" name="zipcode" ref="zipCode" value={opts.form.zipcode} error={errors.zipcode}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Stad" name="city" ref="city" value={opts.form.city} error={errors.city} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div class="country">
      <label for="countrycode">Land</label>
      <select name="countrycode" ref="countrycode" onchange={validateinput} value={opts.form.countrycode}>
        <option value="">- Välj -</option>
        <option each={country in opts.countries} value={country.CountryCode}>{country.CountryName}</option>
      </select>
    </div>
    <div class="checkbox">
      <label for="defaultaddress">Standard leveransadress</label>
      <input type="checkbox" name="defaultaddress" ref="defaultAddress"></input>
    </div>
    <div>
      <button class="defaultButton" type="submit">Lägg till adress</button>
    </div>
  </form>
  <script>
    import './style.scss'
    import deliveryValidationRules from './deliveryValidationRules'
    import {
      addDeliveryFormChanged,
      addDeliveryInfo
    } from '../duck'
    import {
      validateObject,
      validate
    } from 'lib/validator'
    this.errors = {}

    const mapStateToOpts = state => {
      return {
        user: state.login.user,
        countries: state.app.countries,
        form: state.profile.addDeliveryForm
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      let validation = validate(deliveryValidationRules[name], value)
      this.errors[name] = validation.error
    }

    this.validateinput = (e) => {
      const {
        name,
        value
      } = e.target

      this.opts.dispatch(addDeliveryFormChanged(name, value))

      if (this.errors[name] && this.errors[name].length) {
        const validation = validate(deliveryValidationRules[name], value)
        this.errors[name] = validation.error
      }
    }

    this.addDeliveryInfo = (e) => {
      e.preventDefault()
      e.preventUpdate = true

      const payload = {
        ...this.opts.form,
        DefaultAddress: this.refs.defaultAddress.checked
      }

      const validate = validateObject(deliveryValidationRules, payload)

      if (!validate.valid) {
        this.errors = validate.properties
        this.update()
        return
      } else {
        this.errors = {}
      }

      if (this.opts.user) {
        const id = this.opts.user.Id
        this.opts.dispatch(addDeliveryInfo(id, payload))
      }
    }
  </script>
</add-delivery-form>