<login-feature>
  <div>
    <h2>Är ni återförsäljare loggar ni in nedan.</h2>
    <!--  <div>Loggar ni in med fel uppgifter 3 gånger blir ni blockerade, försök igen efter en stund eller byt ert lösenord genom att
      klicka på glömt lösenord.</div>  -->
  </div>
  <h2>Logga in</h2>
  <div class="logincontainer">
    <div if={opts.login.invalidLogin} class="errorMessage">Fel användarnamn eller lösenord</div>
    <form onsubmit={doLogin}>
      <default-input type="text" label="E-postadress" ref="username" required={true} />
      <default-input type="password" label="Lösenord" ref="password" required={true} />
      <button class="defaultButton" type="submit">Logga in</button>
    </form>
    <div class="resetpw">
      <a href="#resetpassword">Glömt lösenord?</a>
    </div>
    <loading-overlay if={opts.login.loading} />
  </div>
  <script>
    import './style.scss'
    import route from 'riot-route'
    import 'tags/common/inputs/default-input'
    import 'tags/common/loading-overlay'
    import {
      login
    } from './duck'

    const mapStateToOpts = (state) => {
      return {
        login: state.login
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.doLogin = (e) => {
      e.preventDefault()
      e.preventUpdate = true

      const username = this.refs.username.value
      const password = this.refs.password.value

      if (username.length && password.length) {
        this.opts.dispatch(login(username, password))
      }
    }

    this.on('update', () => {
      if (this.opts.login.loggedIn) {
        route('shop')
      }
    })
  </script>
</login-feature>