import initialState from './initialState'

import {
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const FETCH_ARTICLES = 'iam/features/shop-feature/FETCH_ARTICLES'
const FETCH_ARTICLES_SUCCESS = 'iam/features/shop-feature/FETCH_ARTICLES_SUCCESS'
const FETCH_ARTICLES_FAILED = 'iam/features/shop-feature/FETCH_ARTICLES_FAILED'

const FETCH_HIGHLIGHTED_ARTICLES = 'iam/features/shop-feature/FETCH_HIGHLIGHTED_ARTICLES'
const FETCH_HIGHLIGHTED_SUCCESS = 'iam/features/shop-feature/FETCH_HIGHLIGHTED_SUCCESS'
const FETCH_HIGHLIGHTED_FAILED = 'iam/features/shop-feature/FETCH_HIGHLIGHTED_FAILED'

const SEARCH_ARTICLES = 'iam/features/shop-feature/SEARCH_ARTICLES'
const SEARCH_ARTICLES_SUCCESS = 'iam/features/shop-feature/SEARCH_ARTICLES_SUCCESS'
const SEARCH_ARTICLES_FAILED = 'iam/features/shop-feature/SEARCH_ARTICLES_FAILED'

export const fetchArticles = (productGroupId, orderBy) => {
  productGroupId = Number(productGroupId)
  orderBy = orderBy || 'CreatedDesc'

  const criteria = {
    productGroupId,
    orderBy
  }
  return {
    types: [FETCH_ARTICLES, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILED],
    callApi: {
      endpoint: 'api/Articles/',
      method: 'GET',
      data: criteria
    },
    productGroupId: productGroupId,
    orderBy: orderBy
  }
}

export const fetchHighlightedArticles = () => {
  return {
    types: [FETCH_HIGHLIGHTED_ARTICLES, FETCH_HIGHLIGHTED_SUCCESS, FETCH_HIGHLIGHTED_FAILED],
    callApi: {
      endpoint: 'api/HighlightedArticles/',
      method: 'GET'
    }
  }
}

export const searchArticles = (criteria, orderBy) => {
  orderBy = orderBy || 'CreatedDesc'

  const payload = {
    ...criteria,
    orderBy
  }

  return {
    types: [SEARCH_ARTICLES, SEARCH_ARTICLES_SUCCESS, SEARCH_ARTICLES_FAILED],
    callApi: {
      endpoint: 'api/Search/',
      method: 'GET',
      data: payload
    },
    orderBy: orderBy,
    criteria: criteria
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
      return {
        ...state,
        result: null,
        search: false,
        highlights: false,
        loading: false
      }
    case FETCH_ARTICLES:
      return {
        ...state,
        loading: true,
        result: null,
        productGroupId: action.productGroupId,
        orderBy: action.orderBy,
        search: false,
        highlights: false,
        searchCriteria: null,
        isSearched: false
      }
    case FETCH_HIGHLIGHTED_ARTICLES:
      return {
        ...state,
        loading: true,
        result: null,
        productGroupId: null,
        orderBy: null,
        search: false,
        searchCriteria: null,
        highlights: true,
        isSearched: false
      }
    case SEARCH_ARTICLES:
      return {
        ...state,
        search: true,
        highlights: false,
        searchCriteria: action.criteria,
        orderBy: action.orderBy,
        loading: true,
        productGroupId: null,
        result: null,
        isSearched: true
      }
    case FETCH_HIGHLIGHTED_SUCCESS:
    case FETCH_ARTICLES_SUCCESS:
    case SEARCH_ARTICLES_SUCCESS:
      return {
        ...state,
        result: action.data,
        loading: false,
        search: false
      }
    case FETCH_HIGHLIGHTED_FAILED:
    case FETCH_ARTICLES_FAILED:
    case SEARCH_ARTICLES_FAILED:
      return {
        ...state,
        result: null,
        loading: false,
        isSearched: false
      }
    default:
      return state
  }
}