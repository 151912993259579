<default-select>
  <div>
    <label for="{name}" if="{label}">{label}
      <span if={required} class="required">*</span>
    </label>
    <select name="{name}" disabled="{disabled}" class="{error: error.length}" value="{value}" ref="{ref}" placeholder="{placeholder}"
      onchange="{onSelectChange}">
      <option each="{item in items}" value="{item.value}" selected="{selectedvalue === item.value}">{item.text}</option>
    </select>
    <div if="{error.length}" class="errormsg">{error}</div>
  </div>
  <script>
    import './style.scss'
    this.label = opts.label || ''
    this.name = opts.name || ''
    this.items = opts.items || null
    this.placeholder = opts.placeholder || ''
    this.ref = opts.ref || ''
    this.value = opts.riotvalue || ''
    this.error = opts.error || ''
    this.disabled = opts.disabled || false
    this.required = opts.required || false
    this.selectedvalue = opts.riotValue || opts.value

    this.onSelectChange = function (e) {
      if (this.opts.onchanged)
        this.opts.onchanged(e)
    }

    this.on('update', () => {
      this.error = opts.error || ''
      this.label = opts.label || ''
      this.placeholder = opts.placeholder || ''
      this.disabled = opts.disabled || false
      this.items = opts.items || null
      this.selectedvalue = opts.riotValue || opts.value
    })
  </script>
</default-select>