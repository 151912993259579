export default {
  showWidget: false,
  materials: {
    items: null,
    loading: false
  },
  colors: {
    items: null,
    loading: false
  }
}