<default-input>
  <div>
    <label for="{name}" if="{label}">{label}
      <span if={required} class="required">*</span>
    </label>
    <input type="{type}" min="{min}" max="{max}" name="{name}" class="{error: error.length}" ref="{ref}" placeholder="{placeholder}"
      disabled="{disabled}" value="{value}" oninput="{onInput}" onblur="{onBlur}" autocomplete="{autocomplete}" />
    <div if="{error.length}" class="errormsg">{error}</div>
  </div>
  <script>
    import './style.scss'
    this.label = opts.label || ''
    this.name = opts.name || ''
    this.placeholder = opts.placeholder || ''
    this.ref = opts.ref || ''
    this.type = opts.type || 'text'
    this.value = opts.riotValue || ''
    this.error = opts.error || ''
    this.disabled = opts.disabled || false
    this.min = opts.min || ''
    this.max = opts.max || ''
    this.required = opts.required || false
    this.autocomplete = opts.autocomplete || 'on'

    this.onInput = function (e) {
      const {
        value
      } = e.target

      if (opts.riotValue === undefined) {
        this.value = value
      }
    }

    this.onBlur = function (e) {
      if (opts.onblur) {
        opts.onblur(e)
      }
    }

    this.on('update', () => {
      this.error = opts.error || ''
      this.label = opts.label || ''
      this.placeholder = opts.placeholder || ''
      this.disabled = opts.disabled || false

      if (opts.riotValue !== undefined)
        this.value = opts.riotValue || ''
    })
  </script>
</default-input>