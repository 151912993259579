<resetpassword-feature>
  <h2>Återställ lösenord</h2>
  <div class="container">
    <form onsubmit={sendForm}>
      <div>
        Glömt ditt lösenord? Vänligen ange din e-postadress. Du kommer att få ett mail med ett nytt lösenord.
      </div>
      <div class="message">
        <div if={opts.sent} class="complete">
          Ditt lösenord har återställts
        </div>
        <div if={opts.error} class="errorMessage">
          Misslyckades med att återställa lösenord. Var vänlig och försök igen!
        </div>
      </div>
      <default-input label="E-postaddress" name="email" ref="email" error={errors.email} oninput={validateinput} onblur={validate}
      />
      <button class="defaultButton">Återställ lösenord</button>
      <loading-overlay if={opts.loading} />
    </form>
  </div>
  <script>
    import './style.scss'
    import 'tags/common/inputs/default-input'
    import 'tags/common/loading-overlay'
    import {
      resetPassword
    } from './duck'
    import validationRules from './validationrules'
    import {
      validate,
      validateObject
    } from 'lib/validator'
    this.errors = {}

    const mapStateToOpts = state => {
      return {
        loading: state.resetpassword.loading,
        sent: state.resetpassword.sent,
        error: state.resetpassword.error
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.sendForm = e => {
      e.preventDefault()
      const payload = {
        email: this.refs.email.value
      }
      const validate = validateObject(validationRules, payload)

      if (!validate.valid) {
        this.errors = validate.properties
        return
      } else {
        this.errors = {}
      }

      this.opts.dispatch(resetPassword(payload.email))
      this.refs.email.value = ''
    }

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      const validation = validate(validationRules[name], value)
      this.errors[name] = validation.error
    }

    this.validateinput = (e) => {
      const {
        name,
        value
      } = e.target

      if (this.errors[name] && this.errors[name].length) {
        const validation = validate(validationRules[name], value)
        this.errors[name] = validation.error
      }
    }
  </script>
</resetpassword-feature>