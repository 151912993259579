<site-header>
  <accept-cookie />
  <header>
    <div class="logo">
      <a href="#">
        <img src="{IAMinterior}" alt="">
      </a>
    </div>
  </header>
  <script>
    import './style.scss'
    import 'tags/features/shoppingcart/shoppingcart-widget'
    import 'tags/features/search-widget'
    import IAMinterior from 'images/siteimages/logo.png'
    import 'tags/features/accept-cookie'
    import {
      logout
    } from 'tags/features/login-feature/duck'

    this.IAMinterior = IAMinterior

    const mapStateToOpts = state => {
      return {
        login: state.login
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.logout = () => {
      this.opts.dispatch(logout())
    }
  </script>
</site-header>