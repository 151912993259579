<image-slider>
  <div ref="slider" class="container">
    <div class="images" ref="image1"></div>
    <div class="images" ref="image2"></div>
  </div>
  <h2 class="header" if={header}>{header}</h2>
  <loading-spinner if={opts.loading} />
  <script>
    import './style.scss'

    this.images = opts.images || []
    this.header = opts.header || null
    this.switchInterval = opts.interval || 6000
    this.endpoint = opts.endpoint || 'api/webimage/'
    this.apiUrl = process.env.API_URL
    this.groupby = 2
    this.oldGroup = null
    this.currentGroup = 0
    this.timer = null
    this.groups = null
    this.aspectRatio = 1.5

    this.calculateGroups = () => {
      if (!this.images.length || this.groups) return

      const count = this.images.length
      const groupCount = Math.floor(count / this.groupby)

      if (groupCount === 0) return

      this.groups = []
      for (let i = 0; i < groupCount; i++) {
        let index = i * this.groupby
        const images = this.images.slice(index, index + this.groupby)
        this.groups.push(images)
      }

      this.updateImages()
    }

    this.showNext = () => {
      if (!this.groups)
        return

      this.oldGroup = this.currentGroup
      

      if (this.currentGroup === (this.groups.length - 1))
        this.currentGroup = 0
      else
        this.currentGroup += 1

      this.updateImages()
    }

    this.updateImages = () => {
      if (!this.images || !this.groups) return
      const image1 = this.refs.image1
      const image2 = this.refs.image2


      this.updateImage(image1, 0)
      setTimeout(() => {
        this.updateImage(image2, 1)
      }, 100)
    }

    this.updateImage = (ref, index) => {
      if (!ref) return

      if (this.oldGroup !== null) {
        const oldId = this.groups[this.oldGroup][index].Id
        const oldImage = ref.querySelector('[data-id="' + oldId + '"]')

        if (oldImage) {
          oldImage.classList.remove('fadeIn')
          oldImage.classList.add('fadeOut')
          oldImage.addEventListener('animationend', this.removeImage)
          oldImage.addEventListener('webkitAnimationEnd', this.removeImage)
        }
      }

      const newId = this.groups[this.currentGroup][index].Id

      let img = document.createElement('img')
      img.src = this.apiUrl + this.endpoint + newId
      img.classList.add('animated')
      img.classList.add('fadeIn')
      img.setAttribute('data-id', newId)

      ref.appendChild(img)
    }

    this.removeImage = (e) => {
      const {
        target
      } = e

      if (target.parentElement) {
        target.parentElement.removeChild(target)
      }
    }

    this.startTimer = () => {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.showNext()
          this.update()
        }, this.switchInterval)
      }
    }

    this.stopTimer = () => {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }

    this.setHeight = () => {
      const bounding = this.root.getBoundingClientRect()
      this.root.style.height = (bounding.width / this.groupby) / this.aspectRatio + 'px'
    }

    this.setBack = () => {
      this.startTimer()
    }

    this.setAway = () => {
      this.stopTimer()
    }

    this.visabilityChange = () => {
      if (document.visibilityState !== undefined) {
        if (document.visibilityState === 'hidden') {
          this.setAway()
        } else if (document.visibilityState === 'visible') {
          this.setBack()
        }
      }
    }

    this.on('update', () => {
      const triggerCalculate = this.images !== opts.images

      this.images = opts.images || []
      this.header = opts.header || null

      if (triggerCalculate) this.calculateGroups()
    })

    this.on('unmount', () => {
      this.stopTimer()
      window.removeEventListener('resize', this.setHeight)
      window.removeEventListener('visibilitychange', this.visabilityChange)
    })

    this.on('mount', () => {
      this.setHeight()
      this.calculateGroups()
      this.startTimer()
      window.addEventListener('resize', this.setHeight)
      window.addEventListener('visibilitychange', this.visabilityChange)
    })

    
  </script>
</image-slider>