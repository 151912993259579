<site-navigation>
  <nav ref="nav">
    <div class="navigation">
      <!--  <close-button onclick={closeMenu} />  -->
      <a each={page in generatedItems} href={ (page.external ? '' : '#') + page.link} class={active: page.link===currentpage}>{page.name}</a>
      <select onchange={pageChanged}>
        <option disabled selected>Navigera till...</option>
        <option each={page, i in generatedItems} value={i}>{page.name}</a>
      </select>

    </div>
    <div class="phone">
      <span>Telefon: {opts.company.Telephone}</span>
    </div>
  </nav>

  <script>
    import './style.scss'
    import route from 'riot-route'
    this.currentpage = opts.currentpage || ''
    this.isLoggedInLocal = null
    this.items = [
      {
        link: '/',
        name: 'Hem'
      },
      {
        link: 'https://shop.app4sales.net/iaminterior/login.html',
        name: 'Webbshop',
        external: true
      },
      {
        link: 'imagesdl',
        name: 'Nedladding av Bilder',
        authenticated: true
      },
      {
        link: 'aboutus',
        name: 'Om Oss'
      },
      {
        link: 'reseller',
        name: 'Återförsäljare'
      },
      {
        link: 'information',
        name: 'Information'
      },
      {
        link: 'contact',
        name: 'Kontakt'
      }
    ]
    this.generatedItems = []


    const mapStateToOpts = state => {
      return {
        company: state.app.settings.Company,
        isLoggedIn: state.login.loggedIn
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.pageChanged = e => {
      const {
        value
      } = e.target

      const item = this.items[value]

      if(item.external) 
        window.location = item.link
      else
        route(item.link)
    }

    this.generateItems = () => {
      this.generatedItems = this.items.filter(item => !item.authenticated || item.authenticated && this.opts.isLoggedIn)

      this.isLoggedInLocal = this.opts.isLoggedIn
    }

    this.on('update', () => {
      this.currentpage = this.opts.currentpage || ''
      if (this.isLoggedInLocal !== this.opts.isLoggedIn) {
        this.generateItems()
      }
    })
  </script>
</site-navigation>