export default {
  company: {
    required: true,
    minLength: 2
  },
  name: {
    required: true,
    minLength: 2
  },
  address: {
    required: true,
    minLength: 2
  },
  zipcode: {
    required: true,
    minLength: 5
  },
  city: {
    required: true,
    minLength: 2
  },
  identity: {
    required: true,
  },
  email: {
    required: true,
    email: true,
  },
  telephone: {
    required: true,
    minLength: 5
  },
  website: {
    required: true
  },
  message: {
    required: true
  }
}