<information-page>
  <main-slider />
  <h2 class="header">Information om att beställa varor hos oss</h2>
  <div class="infoContainer col-md-9">
    <div class="priceInfo">
      <h3>Prisinformation</h3>
      <div class="textdivs" if={opts.webTexts}>
        <raw content={opts.webTexts.Price} />
      </div>
    </div>
    <div class="deliveryInfo">
      <h3>Fraktvillkor Sverige</h3>
      <div class="textdivs" if={opts.webTexts}>
        <raw content={opts.webTexts.Deliveryterms} />
      </div>
    </div>
    <div class="paymentInfo">
      <h3>Betalningsvillkor</h3>
      <div class="textdivs" if={opts.webTexts}>
        <raw content={opts.webTexts.Paymentterms} />
      </div>
    </div>
    <div class="complaintInfo">
      <h3>Reklamationer och transportskador</h3>
      <div class="textdivs" if={opts.webTexts}>
        <raw content={opts.webTexts.Complaints} />
      </div>
    </div>
    <div class="infoInfo">
      <h3>Information</h3>
      <div class="textdivs" if={opts.webTexts}>
        <raw content={opts.webTexts.Information} />
      </div>
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/features/sliders/main-slider'
    import 'tags/common/raw'

    const mapStateToOpts = state => {
      return {
        webTexts: state.app.texts
      }
    }

    this.reduxConnect(mapStateToOpts)
  </script>
</information-page>