export default {
  settings: {
    Company: {},
    EstimatedDeliveryDate: null,
    Instagram: null
  },
  loadingSettings: false,
  texts: null,
  countries: null,
  deliveryWays: null
}