export default {
  updateAccountInfo: null,
  updateDeliveryInfo: null,
  addDeliveryInfo: null,
  loading: false,
  accountForm: null,
  deliveryForm: {},
  addDeliveryForm: {},
  visitingAddress: {},
  errorMessage: null
}