import initialState from './intialState'

const CONTACT = 'iam/features/contact-feature/CONTACT'
const CONTACT_SUCCESS = 'iam/features/contact-feature/CONTACT_SUCCESS'
const CONTACT_FAILED = 'iam/features/contact-feature/CONTACT_FAILED'

const FORM_CHANGED = 'iam/features/contact-feature/FORM_CHANGED'

//Actions
export const sendContact = (payload) => {
  return {
    types: [CONTACT, CONTACT_SUCCESS, CONTACT_FAILED],
    callApi: {
      endpoint: 'api/contact',
      method: 'POST',
      data: payload
    }
  }
}

export const formChanged = (name, value) => {
  return {
    type: FORM_CHANGED,
    name,
    value
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT:
      return {
        ...state,
        loading: true,
        sent: false,
        error: false
      }
    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true,
        form: {}
      }
    case CONTACT_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case FORM_CHANGED:
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value
        }
      }
    default:
      return state
  }
}