<pagination-view>
  <div class="container" if={items.length> 1}>
    <!--  <div onclick={onFirst} if={showBack}>«</div>  -->
    <div onclick={onBack} if={showBack}>‹</div>
    <div onclick={onClick} each={item in items} class={active: item===currentItem}>{item}</div>
    <div onclick={onNext} if={showForward}>›</div>
    <!--  <div onclick={onLast} if={showForward}>»</div>  -->
  </div>
  <script>
    import './style.scss'
    this.totalCount = Number(opts.count) || 1
    this.currentItem = Number(opts.current) || 1
    this.take = Number(opts.take) || 12
    this.items = []
    this.showBack = false
    this.showForward = false

    this.generateNavigation = () => {
      const pages = Math.ceil(this.totalCount / this.take)

      this.items = []
      for (let i = 1; i <= pages; i++) {
        this.items.push(i)
      }
    }

    this.updateStyles = () => {
      const hasHiddenStyle = this.root.classList.contains('hidden')
      if (this.items.length > 1 && hasHiddenStyle)
        this.root.classList.remove('hidden')
      else if (this.items.length <= 1 && !hasHiddenStyle)
        this.root.classList.add('hidden')
    }

    this.onFirst = () => {
      opts.onchange(1)
    }

    this.onBack = () => {
      const page = this.currentItem - 1
      opts.onchange(page)
    }

    this.onClick = (e) => {
      const item = e.item.item
      opts.onchange(item)
    }

    this.onNext = () => {
      const page = this.currentItem + 1
      opts.onchange(page)
    }

    this.onLast = () => {
      opts.onchange(this.items.length)
    }

    this.updateButtons = () => {
      this.showBack = this.currentItem !== 1
      this.showForward = this.currentItem !== this.items.length
    }

    this.on('update', () => {
      if (this.totalCount !== opts.count || this.currentItem !== opts.current) {
        this.totalCount = Number(opts.count) || 1
        this.currentItem = Number(opts.current) || 1
        this.generateNavigation()
      }
    })

    this.on('updated', () => {
      this.updateStyles()
    })

    this.on('before-mount', () => {
      this.generateNavigation()
      this.updateButtons()
      this.updateStyles()
    })
  </script>
</pagination-view>