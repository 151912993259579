import {
  combineReducers
} from 'redux'
import related from 'tags/features/article/related-articles/duck'
import article from 'tags/features/article/article-feature/duck'

const reducer = combineReducers({
  related,
  article
})

export default reducer