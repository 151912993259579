<search-widget onmouseover={showDropdown} onmouseleave={hideDropdown} onclick={toggleDropdown}>
  <div class="container">
    <div class="icon">
      <raw content={magnifier} />
    </div>
    <div class="arrow">
      <i ref="arrow" class="arrowDown"></i>
    </div>
  </div>
  <div class="dropTab {show: opts.showWidget}">
    <div class="content">
      <form onsubmit={search}>
        <default-input type="search" label="Sökord / Artikelnummer" ref="searchtext" name="searchtext" value={searchForm.searchtext}
          oninput={this.onChange} autocomplete="off"></default-input>

        <div class="group" if={opts.productgroups.items}>
          <label for="productgroupid">Kategori</label>
          <select name="productgroupid" onchange={this.onChange}>
            <option value="">- Alla -</option>
            <option each={group in opts.productgroups.items} value={group.ProductGroupId}>{group.Name}</option>
          </select>
        </div>

        <div class="group" if={opts.materials.items}>
          <label for="materialid">Material</label>
          <select name="materialid" onchange={this.onChange}>
            <option value="">- Alla -</option>
            <option each={material in opts.materials.items} value={material.Id}>{material.Name}</option>
          </select>
        </div>

        <label>Färger</label>
        <div class="group searchByColor">
          <color-checkbox each={color in opts.colors.items} colorid={color.Id} hex={color.Hex} name={color.Name} checked={searchForm.colors.indexOf(color.Id)
            !==- 1} onToggleColor={this.colorChanged} />
        </div>

        <div class="buttons">
          <button class="darkButton" type="button" onClick={this.clear} type="button">Rensa</button>
          <button class="darkButton" type="submit" type="button">Sök</button>
        </div>
      </form>
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import magnifier from 'images/svg/magnifier.svg'
    import 'tags/common/inputs/default-input'
    import 'tags/common/inputs/color-checkbox'
    import route from 'riot-route'
    import {
      showWidget,
      hideWidget,
      toggleWidget,
      fetchMaterials,
      fetchColors
    } from './duck'
    import {
      searchArticles
    } from 'tags/features/products/shop-feature/duck'
    import {
      isMobile
    } from 'lib/utils'
    this.magnifier = magnifier
    this.searchForm = {
      searchtext: '',
      colors: [],
      productgroupid: '',
      materialid: ''
    }

    const mapStateToOpts = state => {
      return {
        showWidget: state.search.showWidget,
        colors: state.search.colors,
        materials: state.search.materials,
        productgroups: state.products.groups
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.search = (e) => {
      e.preventDefault()
      this.opts.dispatch(searchArticles(this.searchForm))
      route('shop/search/articles')
    }

    this.clear = () => {
      this.searchForm = {
        searchtext: '',
        colors: [],
        productgroupid: '',
        materialid: ''
      }
      this.update()
    }

    this.onChange = (e) => {
      const {
        name,
        value
      } = e.target

      this.searchForm[name] = value
    }

    this.colorChanged = (id, checked) => {
      let colors = [
        ...this.searchForm.colors
      ]

      const colorIndex = colors.findIndex(colorId => colorId === id)

      if (colorIndex !== -1 && !checked) {
        colors.splice(colorIndex, 1)
      } else if (colorIndex === -1 && checked) {
        colors.push(id)
      } else {
        return
      }

      this.searchForm.colors = colors
      this.update()
    }

    this.showDropdown = () => {
      if (!this.opts.showWidget && !isMobile())
        this.opts.dispatch(showWidget())
    }

    this.hideDropdown = () => {
      if (this.opts.showWidget)
        this.opts.dispatch(hideWidget())
    }

    this.toggleDropdown = (e) => {
      if (e && e.target && e.target.classList.contains('container')) {
        this.opts.dispatch(toggleWidget())
      }
    }

    this.on('mount', () => {
      this.opts.dispatch(fetchColors())
      this.opts.dispatch(fetchMaterials())
    })
  </script>
</search-widget>