<product-slider>
  <multi-slider images={state.images} loading={state.loading} if={showSlider} />
  <script>
    import './style.scss'
    import 'tags/common/multi-slider'
    import {
      fetchImages
    } from './duck'
    import {
      isMobile
    } from 'lib/utils'
    this.mixin('store')
    this.state = this.store.getState().sliders.products
    this.showSlider = !isMobile()

    this.store.subscribe(() => {
      const newState = this.store.getState().sliders.products
      if (newState !== this.state) {
        this.update({
          state: newState
        })
      }
    })

    this.on('mount', () => {
      if (!this.state.images) {
        this.store.dispatch(fetchImages())
      }
    })
  </script>
</product-slider>