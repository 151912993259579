<accept-cookie>
  <div if={opts.checked && !opts.accepted}>
    Denna webbplats använder <a href="#information">cookies</a> <button onclick={accepted} class="defaultButton">Jag förstår</button>
  </div>
  <script>
    import {
      checkCookie,
      acceptedCookie
    } from './duck'
    import './style.scss'

    const mapStateToOpts = state => {
      return {
        accepted: state.cookie.accepted,
        checked: state.cookie.checked
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.accepted = () => {
      this.opts.dispatch(acceptedCookie())
    }

    this.on('mount', () => {
      this.opts.dispatch(checkCookie())
    })
  </script>
</accept-cookie>