<vertical-slider>
  <div class="container" ref="container">
    <div class="slider" ref="slider" style={sliderStyle}>
      <img src={apiUrl + endpoint + image.Id} alt="Slider" each={image in images} style={imageStyle}>
    </div>
  </div>
  <script>
    import './style.scss'
    this.images = opts.images || []
    this.endpoint = opts.endpoint || ''
    this.showcount = opts.showcount || 1
    this.apiUrl = process.env.API_URL
    this.calculated = false
    this.containerHeight = null
    this.imageHeight = null
    this.currentImage = opts.currentimage || 0

    this.imageStyle = {
      height: 0 + 'px'
    }

    this.sliderStyle = {
      transform: 'translateY(0px)'
    }

    this.generateImageStyles = () => {
      this.imageStyle.height = (this.imageHeight || 0) + 'px'
    }

    this.genereateSliderStyles = () => {
      this.sliderStyle.transform = 'translateY(-' + this.currentImage * (this.imageHeight + 2) + 'px)'
    }

    this.calculate = () => {
      if (this.calculated || !this.images.length) return

      this.containerHeight = this.refs.container.clientHeight
      this.imageHeight = this.containerHeight / this.showcount

      this.calcuated = true
      this.generateImageStyles()
    }

    this.on('update', () => {
      this.images = opts.images || []
      this.currentImage = opts.currentimage || 0

      this.calculate()
      this.genereateSliderStyles()
    })

    this.on('mount', () => {
      this.calculate()
      this.update()
    })
  </script>
</vertical-slider>