<article-page>
  <article-feature articleid={this.articleId} />
  <script>
    import 'tags/features/article/article-feature'
    import './style.scss'

    this.on('route', (action, item) => {
      this.articleId = item
      this.update()
    })
  </script>
</article-page>