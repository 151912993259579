<article-item>
  <div class="header">{article.ArticleTextShort}</div>
  <div class="addedToCartText" if={addedToCart}>"{opts.article.ArticleText}" har lagts i din varukorg</div>
  <div>
    <div class="image">
      <single-slider if={article.ImageId} images={article.ImageIds} defaultimage={article.ImageId} endpoint={endpoint} alt={article.ArticleText} onimageclicked={showArticle} />
      <img if={!article.ImageId} riot-src={noimg} alt={article.ArticleText}>
    </div>
    <div class="infoContainer">
      <span class="subHeader">ArtikelNr: {article.ArticleNumber}</span>
      <span class="description" if={article.Description}>{article.Description}</span>
      <div class="info">
        <span>({article.QuantityPerPackage} st/fp)</span>
      </div>
      <span class="price">Pris: {article.Price.format()} Kr</span>
      <span class="delivery" if={article.EstimatedDeliveryDate}>På väg in ({getDate()})</span>
      <span class="delivery" if={!article.EstimatedDeliveryDate && article.QuantityAvailable}>Finns i lager</span>
      <span class="delivery" if={article.QuantityAvailable <=0}>Slutsåld</span>
      <form if={loggedIn} onsubmit={this.addToCart}>
        <div>
          <select ref="quantity" name="quantity" onchange={quantityChanged} value={selectedValue}>
            <option value="" disabled selected>Välj ett alternativ...</option>
            <option each={quantity in addQuantities} value={quantity}>{quantity}</option>
          </select>
        </div>
        <button type="submit" class="defaultButton">Lägg i varukorgen</button>
      </form>
      <div class="spnBtn">
        <a onclick={showArticle}>Läs mer</a>
      </div>
    </div>
  </div>
  <script>
    import './style.scss'
    import route from 'riot-route'
    import noimg from 'images/siteimages/noimage.jpg'
    import 'tags/common/single-slider'
    import {
      urlName,
      formatDeliverytime,
      getDate
    } from 'lib/utils'
    this.endpoint = opts.endpoint || 'api/articlethumbnail/'
    this.article = opts.article || {}
    this.noimg = noimg
    this.estimatedDeliveryDate = opts.estimateddelivery
    this.loggedIn = opts.loggedin || false
    this.addQuantities = []
    this.addedToCart = false

    this.getDate = () => getDate(this.opts.article.EstimatedDeliveryDate)
    this.formatDeliverytime = () => formatDeliverytime(this.article.EstimatedDeliveryDate, this.estimatedDeliveryDate)

    this.showArticle = () => {
      route('article/' + urlName(this.article.ArticleText) + '/' + this.article.Id)
    }

    this.addToCart = (e) => {
      e.preventDefault()

      const articleId = this.opts.article.Id
      const quantity = this.refs.quantity.value

      if (quantity && quantity !== '' && !this.opts.addingToCart) {
        this.addedToCart = true
        this.opts.addtocart(articleId, quantity)
      }
    }

    this.on('update', () => {
      this.loggedIn = this.opts.loggedin || false
    })

    this.on('before-mount', () => {
      if (!this.addQuantities.length && this.opts.article) {
        this.addQuantities = []

        for (let i = 1; i <= 4; i++) {
          this.addQuantities.push(this.opts.article.QuantityPerPackage * i)
        }
      }
    })
  </script>
</article-item>