import {
  combineReducers
} from 'redux'
import app from 'tags/app/duck'
import sliders from 'tags/features/sliders/duck'
import products from 'tags/features/products/duck'
import shoppingcart from 'tags/features/shoppingcart/duck'
import login from 'tags/features/login-feature/duck'
import article from 'tags/features/article/duck'
//import productCategoryInfo from 'tags/shop-pages/product-page/duck'
//import articles from 'tags/shop-pages/shop-page/shop-feature/duck'
import resellers from 'tags/features/resellers-feature/duck'
import contact from 'tags/features/contact-feature/duck'
import resetpassword from 'tags/features/resetpassword-feature/duck'
import cookie from 'tags/features/accept-cookie/duck'
import profile from 'tags/pages/profile-page/duck'
import search from 'tags/features/search-widget/duck'
import orders from 'tags/features/orders-feature/duck'
import contactpage from 'tags/pages/contact-page/duck'
import aboutus from 'tags/pages/aboutus-page/duck'

const rootReducer = combineReducers({
  app,
  sliders,
  products,
  shoppingcart,
  login,
  article,
  resellers,
  contact,
  resetpassword,
  cookie,
  profile,
  search,
  orders,
  contactpage,
  aboutus
})

export default rootReducer