<color-checkbox>
  <div className="colorCheckbox" title={name} onClick={this.onClick}>
    <input type="checkbox" value={id} name="color-{id}" checked={checked} />
    <label htmlFor="color-{id}" style={style}></label>
  </div>
  <script>
    import './style.scss'
    import multicolor from 'images/multicolor.png'
    this.id = opts.colorid || ''
    this.hex = opts.hex || null
    this.name = opts.name || ''
    this.checked = opts.checked || false
    this.style = {}
    this.multicolor = multicolor

    this.onClick = () => {
      const {
        id,
        checked
      } = this

      this.opts.ontogglecolor(id, !checked)
    }

    this.on('update', () => {
      this.checked = this.opts.checked || false
    })

    this.on('before-mount', () => {
      if (this.hex) {
        this.style['background-color'] = this.hex
      } else {
        this.style['background-image'] = `url(${this.multicolor})`
      }
    })
  </script>
</color-checkbox>