export default {
  loading: false,
  data: null,
  showWidget: false,
  checkout: { 
    deliveryAddressId : null,
    partialDelivery: ' ',
    wayOfDeliveryId : null,
    orderInfoText : null,
  },
  orderComplete: false,
  hasOpenOrder: null,
  orderError: false,
  errors: {},
}