<shoppingcart-feature>
  <div if={!opts.shoppingcart.loading && opts.shoppingcart.data && !opts.shoppingcart.orderComplete} class="cartForm row">
    <div class="header col-sm-12 xs-hide">
      <span class="col-sm-8">Produkt</span>
      <span class="col-sm-2 end-sm">Antal</span>
      <span class="col-sm-1 center-sm">Pris</span>
      <span class="col-sm-1 end-sm">Total</span>
    </div>
    <shoppingcart-item class="col-xs-12 row" each={row in opts.shoppingcart.data.Rows} cartitem={row}></shoppingcart-item>
  </div>
  <div class="col-xs-12 row" if={!opts.shoppingcart.loading && opts.shoppingcart.data && opts.user && !opts.shoppingcart.orderComplete}>
    <div class="summary col-sm-6 col-xs-12">
      <h2 class="summaryHeader col-xs-12">
        Alternativ
      </h2>

      <div class="delivery col-xs-12 row">
        <div class="col-xs-6 start-xs">
          <span>Delleverans</span>
        </div>
        <div class="deliveryPartial col-xs-6">
          <default-select onchanged={setPartialDelivery} value={this.opts.checkout.partialDelivery} items={partialDeliveryValues}
            required={true} name="partialDelivery" ref="partialDelivery" error={opts.errors.partialDelivery}>
          </default-select>
        </div>
      </div>

      <div class="delivery col-xs-12 row">
        <div class="col-xs-6 start-xs">
          <span>Leveransadress</span>
        </div>
        <div class="deliveryAddress col-xs-6">
          <select onchange={deliveryAddressChosen} name="deliveryAddress" ref="deliveryAddress">
            <option each={address in opts.user.DeliveryAddresses} selected={address.Id===opts.checkout.deliveryAddressId}
              value="{address.Id}">{address.Name}, {address.City}, {address.Address1}
            </option>
          </select>
        </div>
      </div>

      <div class="delivery col-xs-12 row">
        <div class="col-xs-6 start-xs">
          <span>Frakt</span>
        </div>
        <div class="deliveryOption col-xs-6">
          <div each={delivery in opts.deliveryWays}>
            <label>
              <input onclick={changeDelivery} checked="{opts.checkout.wayOfDeliveryId===delivery.Id ? true : false}"
                type="radio" name="delivery" value="{delivery.Id}" ref="localdelivery">{delivery.Name}
              </input>
              <span if={delivery.Price>0}>: {delivery.Price.format(2)}kr</span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 row">
        <div class="col-xs-12 center-xs">
          <h2>Mer Information</h2>
        </div>
        <div class="deliveryTextArea col-xs-12">
          <default-textarea label="Ordernoteringar" ref="ordertext" onblur={setOrderText}></default-textarea>
        </div>
      </div>
    </div>

    <div class="summary col-sm-6 col-xs-12 row">
      <h2 class="summaryHeader col-xs-12">
        <raw content={cart} />
        Varukorg totalt
      </h2>

      <div class="col-xs-12">
        <div class="col-xs-12">
          <div class="priceInfo col-xs-12">
            <div class="col-xs-12 row">
              <span class="col-xs start-xs">Delsumma för varukorg</span>
              <span class="col-xs">{opts.shoppingcart.data.TotalExcludingVat.format(2)}kr</span>
            </div>
            <div class="col-xs-12 row">
              <span class="col-xs start-xs">Moms</span>
              <span class="col-xs">{opts.shoppingcart.data.VatAmount.format(2)}kr</span>
            </div>
            <div class="priceTotal col-xs-12 row">
              <span class="col-xs start-xs">Beställnings totalt</span>
              <span class="col-xs">{opts.shoppingcart.data.TotalAmount.format(2)}kr</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 sellerinfo" if={this.opts.authUsed}>
        <span>Inloggad som säljare</span>
      </div>
      <div class="buttons col-xs-12">
        <div>
          <button onclick={sendCart} disabled={opts.shoppingcart.data.Rows.length <=0}>
            <raw content={cart} />
            Skicka Order
          </button>
        </div>
      </div>
    </div>
  </div>

  <div if={opts.shoppingcart.orderError} class="error">Något gick fel, vänligen kontakta oss.</div>
  <div class="orderComplete col-xs-12 center-xs" if={opts.shoppingcart.orderComplete}>
    <raw content={nl2br(opts.webTexts.OrderComplete)} />
    <button class="defaultButton" onclick={returnToProducts}>Tillbaks till Produkter</button>
  </div>
  <loading-spinner if={opts.shoppingcart.loading} />
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import 'tags/common/loading-spinner'
    import './shoppingcart-item'
    import 'tags/common/inputs/default-textarea'
    import 'tags/common/inputs/default-select'
    import {
      validate,
      validateObject
    } from 'lib/validator'
    import {
      nl2br
    } from 'lib/utils'
    import validationRules from './validationRules'
    import route from 'riot-route'
    import cart from 'images/svg/shopping-cart.svg'
    import {
      fetchCart,
      setWayOfDelivery,
      setDeliveryAddress,
      setPartialDelivery,
      orderInfoText,
      setErrors,
      sendOrder,
      sendPreCheck,
      resetOrderCompleteState
    } from '../duck'
    import {
      checkLogin
    } from 'tags/features/login-feature/duck'
    this.cart = cart
    this.nl2br = nl2br

    this.partialDeliveryValues = [{
      text: 'Välj ett alternativ...',
      value: ' '
    }, {
      text: 'Ja',
      value: 'true'
    }, {
      text: 'Nej',
      value: 'false'
    }]

    const mapStateToOpts = state => {
      return {
        shoppingcart: state.shoppingcart,
        checkout: state.shoppingcart.checkout,
        deliveryWays: state.app.deliveryWays,
        user: state.login.user,
        loggedIn: state.login.loggedIn,
        errors: state.shoppingcart.errors,
        webTexts: state.app.texts,
        authUsed: state.login.authUsed
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.returnToProducts = () => {
      route('shop')
    }

    this.changeDelivery = (e) => {
      this.opts.dispatch(setWayOfDelivery(Number(e.target.value)))
    }

    this.deliveryAddressChosen = () => {
      const addressId = this.refs.deliveryAddress.value
      if (addressId > 0)
        this.opts.dispatch(setDeliveryAddress(addressId))
    }

    this.setPartialDelivery = (e) => {
      const {
        name,
        value
      } = e.target

      const validation = validate(validationRules[name], value)
      this.opts.dispatch(setErrors(name, validation.error))

      const partialDelivery = e.target.value
      this.opts.dispatch(setPartialDelivery(partialDelivery))
    }

    this.setOrderText = (e) => {
      const text = e.target.value
      if (text)
        this.opts.dispatch(orderInfoText(text))
    }

    this.sendCart = () => {
      const {
        checkout
      } = this.opts

      let name = 'partialDelivery'
      let value = checkout.partialDelivery

      const validation = validate(validationRules[name], value)
      this.opts.dispatch(setErrors(name, validation.error))

      const validateObj = validateObject(validationRules, checkout)

      if (validateObj.valid)
        this.opts.dispatch(sendPreCheck(checkout))
    }

    this.sendOrder = (addToOpenOrder) => {
      const {
        checkout
      } = this.opts

      const payload = {
        ...checkout,
        addToOpenOrder: addToOpenOrder
      }

      const validate = validateObject(validationRules, payload)

      if (validate.valid)
        this.opts.dispatch(sendOrder(payload))
    }

    this.showOpenOrderQuestion = () => {
      const element = document.createElement('openorder-question')
      document.body.appendChild(element)
      riot.mount(element, {
        onSendOrder: this.sendOrder
      })
    }

    this.on('update', () => {
      const {
        shoppingcart
      } = this.opts


      if (shoppingcart.hasOpenOrder !== null) {
        if (shoppingcart.hasOpenOrder) {
          this.showOpenOrderQuestion()
        } else {
          this.sendOrder(false)
        }
      }
    })

    this.on('mount', () => {
      if (!this.opts.user || !this.opts.loggedIn)
        return

      const deliveryAddress = this.opts.user.DeliveryAddresses

      let defaultDeliveryAddress = deliveryAddress.find(defaultaddress => defaultaddress.DefaultAddress === true)

      if (defaultDeliveryAddress)
        this.opts.dispatch(setDeliveryAddress(defaultDeliveryAddress.Id))

      if (!this.opts.checkout.wayOfDeliveryId) {
        const deliveryId = this.opts.deliveryWays[0].Id
        this.opts.dispatch(setWayOfDelivery(deliveryId))
      }

      this.opts.dispatch(checkLogin())
    })

    this.on('unmount', () => {
      if (this.opts.shoppingcart.orderComplete) {
        this.opts.dispatch(resetOrderCompleteState(false))
        this.opts.dispatch(fetchCart())
      }
    })
  </script>
</shoppingcart-feature>