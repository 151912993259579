export default {
  phone: {
    minLength: 5
  },
  email: {
    required: true,
    email: true
  },
  currentPassword: {
    required: a => a.newPassword && a.newPassword.length,
  },
  confirmNewPassword: {
    required: a => a.newPassword && a.newPassword.length,
    match: 'newPassword'
  },
  name: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  address1: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  address2: {
    minLength: 2,
    maxLength: 40
  },
  zipcode: {
    required: true,
    minLength: 5
  },
  city: {
    required: true,
    minLength: 2,
    maxLength: 40
  }
}